ignoreResize = [];

CheckTopVisibleMenu = function (that) {
    var dropdownMenu = $('.dropdown-menu:not(.fixed_menu_ext):visible').last();
    if (dropdownMenu.length) {
        dropdownMenu.find('a').css('white-space', '');
        dropdownMenu.css('left', '');
        dropdownMenu.css('right', '');
        dropdownMenu.removeClass('toright');

        var dropdownMenu_left = dropdownMenu.offset().left;
        if (typeof(dropdownMenu_left) != 'undefined') {
            var menu = dropdownMenu.parents('.mega-menu');
            var menu_width = menu.actual('outerWidth');
            var menu_left = menu.offset().left;
            var menu_right = menu_left + menu_width;
            var isToRight = dropdownMenu.parents('.toright').length > 0;
            var parentsDropdownMenus = dropdownMenu.parents('.dropdown-menu:not(.fixed_menu_ext)');
            var isHasParentDropdownMenu = parentsDropdownMenus.length > 0;
            if (isHasParentDropdownMenu) {
                var parentDropdownMenu_width = parentsDropdownMenus.first().actual('outerWidth');
                var parentDropdownMenu_left = parentsDropdownMenus.first().offset().left;
                var parentDropdownMenu_right = parentDropdownMenu_width + parentDropdownMenu_left;
            }

            if (parentDropdownMenu_right + dropdownMenu.actual('outerWidth') > menu_right) {
                dropdownMenu.find('a').css('white-space', 'normal');
            }

            var dropdownMenu_width = dropdownMenu.actual('outerWidth');
            var dropdownMenu_right = dropdownMenu_left + dropdownMenu_width;

            if (dropdownMenu_right > menu_right || isToRight) {
                var addleft = 0;
                addleft = menu_right - dropdownMenu_right;
                if (isHasParentDropdownMenu || isToRight) {
                    dropdownMenu.css('left', 'auto');
                    dropdownMenu.css('right', '100%');
                    dropdownMenu.addClass('toright');
                }
                else {
                    var dropdownMenu_curLeft = parseInt(dropdownMenu.css('left'));
                    dropdownMenu.css('left', (dropdownMenu_curLeft + addleft) + 'px');
                }
            }
        }
    }
}

CheckPopupTop = function () {
    var popup = $('.jqmWindow.show');
    if (popup.length) {
        var documentScollTop = $(document).scrollTop();
        var windowHeight = $(window).height();
        var popupTop = parseInt(popup.css('top'));
        var popupHeight = popup.height();
        var popupWidth = parseInt(popup.css('width'));

        if (windowHeight >= popupHeight) {
            // center
            popupTop = (windowHeight - popupHeight) / 2;
        }
        else {
            if (documentScollTop > documentScrollTopLast) {
                // up
                popupTop -= documentScollTop - documentScrollTopLast;
            }
            else if (documentScollTop < documentScrollTopLast) {
                // down
                popupTop += documentScrollTopLast - documentScollTop;
            }

            if (popupTop + popupHeight < windowHeight) {
                // bottom
                popupTop = windowHeight - popupHeight;
            }
            else if (popupTop > 0) {
                // top
                popupTop = 0;
            }
        }
        console.log(-popupWidth / 2);
        popup.css('margin-left', -popupWidth / 2 + 'px');
        popup.css('top', popupTop + 'px');
    }
}

CheckMainBannerSliderVText = function (slider) {
    if (slider.parents('.banners-big').length) {
        var sh = slider.height();
        slider.find('.item').each(function () {
            var curSlideTextInner = $(this).find('.text .inner');
            if (curSlideTextInner.length) {
                var ith = curSlideTextInner.height;
                var p = (ith >= sh ? 0 : Math.floor((sh - ith) / 2));
                curSlideTextInner.css('padding-top', p + 'px');
            }
        });
    }
}

CheckHeaderFixed = function () {
    var header = $('header.canfixed');

    if (header.length) {
        var headerLogoAndMenuRow = header.find('.logo_and_menu-row');
        if (headerLogoAndMenuRow.length) {
            var isHeaderFixed = false;
            var headerCanFix = true;
            var headerFixedHeight = 53;
            var headerNormalHeight = headerLogoAndMenuRow.actual('outerHeight');
            var headerDiffHeight = headerNormalHeight - headerFixedHeight;
            var mobileBtnMenu = $('.btn.btn-responsive-nav');
            $(window).scroll(function () {
                //var headerTop = $('#panel:visible').actual('outerHeight');
                var headerTop = 0;
                var scrollTop = $(window).scrollTop();
                if (!isHeaderFixed) {
                    headerNormalHeight = headerLogoAndMenuRow.actual('outerHeight');
                    headerDiffHeight = headerNormalHeight - headerFixedHeight;
                }

                headerCanFix = !mobileBtnMenu.is(':visible') && !$('.dropdown-menu:not(.fixed_menu_ext):visible').length;

                if (!isHeaderFixed) {
                    if ((scrollTop > headerNormalHeight + headerTop) && headerCanFix) {
                        isHeaderFixed = true;
                        var headerNext = header.next();
                        if (headerNext.length) {
                            var mt = parseInt(headerNext.css('margin-top'));
                        }
                        header.css('top', '-' + headerNormalHeight + 'px');
                        header.addClass('fixed');
                        if (headerNext.length) {
                            headerNext.css('margin-top', headerNormalHeight + mt + 'px');
                        }
                        header.delay(0).animate({top: '0'}, 300);
                    }
                }
                else if (isHeaderFixed || !headerCanFix) {
                    if ((scrollTop <= headerDiffHeight + headerTop) || !headerCanFix) {
                        isHeaderFixed = false;
                        header.removeClass('fixed');
                        var headerNext = header.next();
                        if (headerNext.length) {
                            headerNext.css('margin-top', '');
                        }
                        //CheckTopMenuDotted();
                    }
                }
            });
        }
    }
}

CheckObjectsSizes = function () {
    $('.container iframe,.container object,.container video').each(function () {
        var height_attr = $(this).attr('height');
        var width_attr = $(this).attr('width');
        if (height_attr && width_attr) {
            $(this).css('height', $(this).actual('outerWidth') * height_attr / width_attr);
        }
    });
}
CheckFilterSeletSizes = function () {
    $('.bx_filter_select_block').each(function () {
        var popup = $("#smartFilterDropDown" + $(this).data('id'));
        if (popup.length) {
            popup.css({'width': $(this).css('width')});
        }
    })
}

scrollToTop = function () {
    if ('ROUND_COLOR' !== 'NONE') {
        var _isScrolling = false;
        // Append Button
        $('body').append($('<a />').addClass('scroll-to-top ROUND_COLOR PADDING').attr({
            'href': '#',
            'id': 'scrollToTop'
        }));
        $('#scrollToTop').click(function (e) {
            e.preventDefault();
            $('body, html').animate({scrollTop: 0}, 500);
            return false;
        });
        // Show/Hide Button on Window Scroll event.
        $(window).scroll(function () {
            if (!_isScrolling) {
                _isScrolling = true;
                var bottom = 23,
                    scrollVal = $(window).scrollTop(),
                    windowHeight = $(window).height(),
                    footerOffset = $('footer').offset().top;
                if (scrollVal > 150) {
                    $('#scrollToTop').stop(true, true).addClass('visible');
                    _isScrolling = false;
                }
                else {
                    $('#scrollToTop').stop(true, true).removeClass('visible');
                    _isScrolling = false;
                }
                if ($("footer").length) {
                    CheckScrollToTop();
                }
            }
        });
    }
}

CheckScrollToTop = function () {
    var bottom = 23,
        scrollVal = $(window).scrollTop(),
        windowHeight = $(window).height(),
        footerOffset = $('footer').offset().top;

    if (scrollVal + windowHeight > footerOffset) {
        $('#scrollToTop').css('bottom', bottom + scrollVal + windowHeight - footerOffset);
    }
    else if (parseInt($('#scrollToTop').css('bottom')) > bottom) {
        $('#scrollToTop').css('bottom', bottom);
    }
}

$.fn.equalizeHeights = function (outer, classNull, minHeight) {
    var maxHeight = this.map(function (i, e) {
        var minus_height = 0,
            calc_height = 0;
        if (classNull !== false) {
            minus_height = parseInt($(e).find(classNull).actual('outerHeight'));
        }
        if (minus_height)
            minus_height += 12;
        $(e).css('height', '');
        if (outer == true) {
            calc_height = $(e).actual('outerHeight') - minus_height;
        } else {
            calc_height = $(e).actual('height') - minus_height;
        }
        if (minHeight !== false) {
            if (calc_height < minHeight) {
                calc_height += (minHeight - calc_height);
            }
            if (window.matchMedia('(max-width: 520px)').matches) {
                calc_height = 300;
            }
            if (window.matchMedia('(max-width: 400px)').matches) {
                calc_height = 200;
            }
        }
        return calc_height;
    }).get();

    for (var i = 0, c = maxHeight.length; i < c; ++i) {
        if (maxHeight[i] % 2) {
            --maxHeight[i];
        }
    }


    return this.height(Math.max.apply(this, maxHeight));
}

$.fn.sliceHeight = function (options) {
    function _slice(el) {
        el.each(function () {
            $(this).css('line-height', '');
            $(this).css('height', '');
        });
        if (typeof(options.autoslicecount) == 'undefined' || options.autoslicecount !== false) {
            var elw = (el.first().hasClass('item') ? el.first().actual('outerWidth') : el.first().parents('.item').actual('outerWidth'));
            var elsw = el.first().parents('.items').actual('outerWidth');
            if (!elsw) {
                elsw = el.first().parents('.row').actual('outerWidth');
            }
            if (elsw && elw) {
                options.slice = Math.floor(elsw / elw);
            }
        }
        if (options.slice) {
            for (var i = 0; i < el.length; i += options.slice) {
                $(el.slice(i, i + options.slice)).equalizeHeights(options.outer, options.classNull, options.minHeight);
            }
        }
        if (options.lineheight) {
            var lineheightAdd = parseInt(options.lineheight);
            if (isNaN(lineheightAdd)) {
                lineheightAdd = 0;
            }
            el.each(function () {
                $(this).css('line-height', ($(this).height + lineheightAdd) + 'px');
            });
        }
    }

    var options = $.extend({
        slice: null,
        outer: false,
        lineheight: false,
        autoslicecount: true,
        classNull: false,
        minHeight: false,
        options: false,
    }, options);

    var el = $(this);
    _slice(el);

    $(window).resize(function () { //BX.addCustomEvent('onWindowResize', function(eventdata) {
        ignoreResize.push(true);
        _slice(el);
        if (options.opacity == "Y") {
            el.each(function () {
                $(this).css('opacity', '1');
            });
        }
        ignoreResize.pop();
    });
}

waitingExists = function (selector, delay, callback) {
    if (typeof(callback) !== 'undefined' && selector.length && delay > 0) {
        delay = parseInt(delay);
        delay = (delay < 0 ? 0 : delay);

        if (!$(selector).length) {
            setTimeout(function () {
                waitingExists(selector, delay, callback);
            }, delay);
        }
        else {
            callback();
        }
    }
}


/*function onLoadjqm(hash) {
    if (hash.c.overlay > 0)
        hash.o.prependTo('body');
    var name = $(hash.t).data('name'),
        top = (($(window).height() > hash.w.height()) ? Math.floor(($(window).height() - hash.w.height()) / 2) : 0) + 'px';
    if ($(hash.t).data('autohide')) {
        $(hash.w).data('autohide', $(hash.t).data('autohide'));
    }
    hash.w.addClass('show').css({'margin-left': '-' + Math.floor(hash.w.width() / 2) + 'px', 'top': top});
    hash.w.show();
}

function onHide(hash) {
    if ($(hash.w).data('autohide')) {
        eval($(hash.w).data('autohide'));
    }
    hash.w.hide();
    //hash.w.empty();
    hash.o.remove();
    hash.w.removeClass('show');
}*/


$(document).ready(function () {
    scrollToTop();
    CheckHeaderFixed();


    /*$('.sendrequest_frame').jqm({
        trigger: $(".sendrequest"),
        onShow: function (hash) {
            onLoadjqm(hash);
        },
        onHide: function (hash) {
            onHide(hash);
        },
    });*/

    $(".sendrequest").fancybox({
        margin : 0,
        btnTpl: {
            smallBtn: '<span data-fancybox-close="" class="popup-close fas fa-times"></span>'
        }
    });



    $("[data-fancybox], .fancybox").fancybox({
        buttons: [
            'zoom',
            'close'
        ],

    });

    $('a.modal:has(img), a.fancy:has(img)').fancybox();


    /*$(document).on('click', '.mega-menu .dropdown-menu', function (e) {
        e.stopPropagation()
    });

    $(document).on('click', '.mega-menu .dropdown-toggle.more-items', function (e) {
        e.preventDefault();
    });*/

    $('.table-menu .dropdown,.table-menu .dropdown-submenu,.table-menu .dropdown-toggle').on('mouseenter', function () {
        CheckTopVisibleMenu();
    });

    var n = 0;

    $('.topbar').on('click', '.search-item, #title-search .fa-times', function (e) {
        e.preventDefault();
        $('.header_search').toggleClass('hide');
        $('#title-search-input').focus();
    });

    /* toggle */
    /*var $this = this,
        previewParClosedHeight = 25;

    $('section.toggle > label').prepend($('<i />').addClass('fa fa-plus'));
    $('section.toggle > label').prepend($('<i />').addClass('fa fa-minus'));
    $('section.toggle.active > p').addClass('preview-active');
    $('section.toggle.active > div.toggle-content').slideDown(350, function () {
    });

    $('section.toggle > label').click(function (e) {
        var parentSection = $(this).parent(),
            parentWrapper = $(this).parents('div.toogle'),
            previewPar = false,
            isAccordion = parentWrapper.hasClass('toogle-accordion');

        if (isAccordion && typeof(e.originalEvent) != 'undefined') {
            parentWrapper.find('section.toggle.active > label').trigger('click');
        }

        parentSection.toggleClass('active');

        // Preview Paragraph
        if (parentSection.find('> p').get(0)) {
            previewPar = parentSection.find('> p');
            var previewParCurrentHeight = previewPar.css('height');
            previewPar.css('height', 'auto');
            var previewParAnimateHeight = previewPar.css('height');
            previewPar.css('height', previewParCurrentHeight);
        }

        // Content
        var toggleContent = parentSection.find('> div.toggle-content');

        if (parentSection.hasClass('active')) {
            $(previewPar).animate({
                height: previewParAnimateHeight
            }, 350, function () {
                $(this).addClass('preview-active');
            });
            toggleContent.slideDown(350, function () {
            });
        }
        else {
            $(previewPar).animate({
                height: previewParClosedHeight
            }, 350, function () {
                $(this).removeClass('preview-active');
            });
            toggleContent.slideUp(350, function () {
            });
        }
    });


    $('.accordion-head').on('click', function (e) {
        e.preventDefault();
        if (!$(this).next().hasClass('collapsing')) {
            $(this).toggleClass('accordion-open');
            $(this).toggleClass('accordion-close');
        }
    });*/

    $('a[rel=tooltip], span[data-toggle=tooltip]').tooltip();

    /*setTimeout(function (th) {
        $('.catalog.group.list .item').each(function () {
            var th = $(this);
            if ((tmp = th.find('.image').outerHeight() - th.find('.text_info').outerHeight()) > 0) {
                th.find('.text_info .titles').height(th.find('.text_info .titles').outerHeight() + tmp);
            }

        })
    }, 50);*/

    /*item galery*/
    /*$('.thumbs .item a').on('click', function (e) {
        e.preventDefault();
        $('.thumbs .item').removeClass('current');
        $(this).closest('.item').toggleClass('current');
        $('.slides li' + $(this).attr('href')).addClass('current').siblings().removeClass('current');
    });*/

    $('header.fixed .btn-responsive-nav').on('click', function () {
        $('html, body').animate({scrollTop: 0}, 400);
    });

    /*$('.more_block span').on('click', function () {
        var content_offset = $('.detail .scroll_block').offset();
        $('html, body').animate({scrollTop: content_offset.top - 53}, 400);
    });*/

    /*$('body').on('click', '.form .refresh-page', function () {
        location.href = location.href;
    });*/


    var countbox = ".wwa-numbers";
    var showCount = true;

    if ($(countbox).length) {
        $(window).on("scroll resize", function () {
            if (!showCount) return false;
            var e_top = $(countbox).offset().top;
            var w_top = $(window).scrollTop();

            var delta = 1050;
            var dcf = 2;

            if (w_top + delta >= e_top) {
                $(".spincrement1:not(.decimal)").each(function (e, i) {
                    $(this).animateNumber({
                        number: $(this).text(),
                        numberStep: $.animateNumber.numberStepFactories.separator(' ')
                    }, 1500);
                });
                $(".spincrement1.decimal").each(function (e, i) {
                    $(this).animateNumber({
                        number: $(this).text() * dcf,
                        numberStep: function (now, tween) {
                            var floored_number = Math.floor(now) / dcf,
                                target = $(tween.elem);

                            floored_number = floored_number.toFixed(1);
                            floored_number = floored_number.toString().replace('.', ',');
                            target.text(floored_number);
                        }
                    }, 1500);
                });
                showCount = false;
            }
        });
    }


    //$('.bx_filter_input_container input[type=text]').numeric({allow: "."});

    /* MOBILE MENU */
    ////////////////////////////////////////////////////////////////
    var mobileMenu = function () {
        var topMenu = $(".topbar-menu-wrap > ul").html();
        var menu = $(".table-menu > ul").html();
        menu = "<div class='sidr-inner'><ul>" + topMenu + menu + "</ul></div>";
        return menu;
    };

    $('#side-menu-button').sidr({
        speed: 300,
        side: "left",
        displace: false,
        renaming: true,
        source: mobileMenu,
    });

    $('#sidr').click(function (e) {
        if (e.target !== undefined && e.target.id == 'sidr') {
            e.preventDefault();
            $.sidr('close', 'sidr');
        }
    });

    var backLink = '<a href="#" class="sidr-level-back">Назад</a>';

    // add right arrows to all links
    $(".sidr ul > li").each(function () {
        var $this = $(this);
        var innerUl = $this.find("> ul");
        if (innerUl.length > 0) {
            var $link = $this.find('> a');
            //$link.addClass("sidr-parent").append(rightArrow);
            $link.addClass("sidr-parent");
            if ($this.closest('li').length > 0) {
                var headLink = '<a href="' + $link.attr('href') + '" class="sidr-level-title">' + $link.text() + '</a>';
                var levelHeader = '<li class="sidr-level-header">' + backLink + headLink + '</li>';
                innerUl.prepend(levelHeader);
            }
        }
    });

    // handle click events for all levels
    $(".sidr ul li a.sidr-parent").on("click", function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.parent().siblings().hide();
        $this.hide();
        $this.siblings('ul').show('slide');
    });

    // handle click events for all back links
    $('.sidr ul li a.sidr-level-back').click(function (e) {
        e.preventDefault();
        var $currentMenu = $(this).parent().parent();
        var $prevMenu = $currentMenu.parent();
        var $parentLink = $currentMenu.siblings('a');
        $currentMenu.hide('slide');

        if ($parentLink.css('display') == 'none') {
            $parentLink.show('fast');
        }
        $prevMenu.siblings().show('fast');
    });
    ////////////////////////////////////////////////////////////////


    $(".gallery-types a.gallery-types-item").on("click", function (e) {
        e.preventDefault();
        var $link = $(this);
        var $gallery = $('.gallery');
        //var linkValue = $link.text();

        $gallery.addClass('gallery-loading');
        $link.toggleClass("active");
        // get all active types
        var activeTypes = $(".gallery-types .gallery-types-item.active").map(function () {
            return $(this).text();
        }).get();

        if ($link.hasClass("reset-gallery") || activeTypes.length == 0) {
            $(".gallery-types-item").removeClass("active");
            $(".gallery-item").removeClass("active");
            $gallery.removeClass("filtered");
            $(".reset-gallery").addClass("active");
            setTimeout(function () {
                $gallery.removeClass('gallery-loading');
            },500);
            return;
        }

        $(".reset-gallery").removeClass("active");

        setTimeout(function () {
            $gallery.removeClass('gallery-loading');
        },500);

        $gallery.addClass("filtered");

        // set active/unactive items
        $(".gallery-item").each(function () {
            var $item = $(this);
            var itemType = $item.data("type");
            $item.removeClass("active");
            activeTypes.forEach(function (item, i, arr) {
                if (itemType.indexOf(item) >= 0) {
                    $item.addClass("active");
                }
            });
        });

    });
    ////////////////////////////////////////////////////////////////


    $(".big-slider").owlCarousel({
        loop: true,
        nav: true,
        items: 1,
        autoplay: true,
        autoplayHoverPause: true
    });

    $(".gallery-object").owlCarousel({
        loop: false,
        nav: true,
        items: 1,
        autoplay: false,
        dots: false,
        navText: ["<span class='nav-text'>〈</span>", "<span class='nav-text'>〉</span>"],
    });
    $(".pagegallery").owlCarousel({
        loop: false,
        nav: true,
        autoplay: false,
        dots: false,
        margin: 17,
        navText: ["<span class='nav-text'>〈</span>", "<span class='nav-text'>〉</span>"],
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            768: {
                items: 3
            }
        }
    });

    $(".obj-types-wrap.obj-types-slider").owlCarousel({
        loop: false,
        nav: true,
        autoplay: false,
        dots: false,
        navText: ["<span class='nav-text'>〈</span>", "<span class='nav-text'>〉</span>"],
        responsive: {
            0: {
                items: 2
            },
            480: {
                items: 3
            },
            600: {
                items: 4
            },
            768: {
                items: 6
            },
            1000: {
                items: 8
            },

        },
    });

    $(".bottom-review-wrap").owlCarousel({
        loop: false,
        nav: true,
        autoplay: false,
        dots: false,
        navText: ["〈", "〉"],
        items: 1
    });


    new WOW().init();


    // tabs
    var url = document.location.toString();
    if (url.match('#')) {
        $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
    }
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });
    //


    // Add minus icon for collapse element which is open by default
    $(".collapse.in").each(function () {
        $(this).siblings(".panel-heading").find("a").addClass("expanded");
    });

    // Toggle plus minus icon on show hide of collapse element
    $(".collapse").on('show.bs.collapse', function () {
        $(this).parent().find("a").addClass("expanded");
    }).on('hide.bs.collapse', function () {
        $(this).parent().find("a").removeClass("expanded");
    });

    $("[data-toggle=popover]").popover();

    if ($('.calc-type').length > 0) {
        Calculator.init();
    }
    $('.calc-more').on('click', function (e) {
        e.preventDefault();
       $('.calc-more-form').slideToggle();
    });

});

$(document).on('af_complete', function (event, response) {
    if (response.success) {
        var $form = $(response.form);
        var $formParent = $form.parents('.popup-window');
        if ($formParent.length == 0)
            $formParent = $form.parent();

        $formParent.addClass('form-success-show');
        setTimeout(function () {
            $.fancybox.close();
            $formParent.removeClass('form-success-show');
        }, 4000);

    }
});

Calculator = {
    config: {
        initialized: false,
        calc: $(".calc"),
        type: $(".calc-type"),
        square: $(".calc-square"),
        services: $(".calc-service"),
        discount: $(".calc-discount"),
        addprice: $(".calc-addprice"),
        total: $(".calc-total"),
        percentage: $(".calc-prompt")
    },
    data: {
        price: 0,
        typePrice: 0,
    },
    init: function() {
        this.setType();
        this.calculate();
        this.setTotal();

        this.config.type.on('change', function () {
            Calculator.setType();
        });
        this.config.calc.on('change input', 'input, select', function () {
            Calculator.calculate();
            Calculator.setTotal();
        });

        this.config.initialized = true;
    },
    calculate: function() {
        var price = this.data.typePrice,
            square = parseFloat(this.config.square.val()) || 0,
            coeff = this.getCoeff(square);

        if(square > 100000000000000000)
            return;

        price += this.getServicesPrice();
        price = price * coeff;
        price = price * square;
        this.data.price = price;
    },
    getCoeff: function (square) {
        var coeff = 1;

        if (square <= 50) {
            coeff = 2;
        }
        if ((square > 50) && (square <= 100)) {
            coeff = 1.3;
        }
        if ((square >= 300) && (square <= 500)) {
            coeff = 0.95;
        }
        if ((square > 500) && (square <= 1500)) {
            coeff =  0.93;
        }
        if ((square > 1500) && (square <= 5000)) {
            coeff = 0.9;
        }
        return coeff;
    },
    getServicesPrice: function() {
        var $services = this.config.services.filter(":checked"),
            servicesPrice = 0;

        if ($services.length > 0) {
            $services.each(function () {
                servicesPrice += parseInt($(this).data('price_add'));
            });
        }
        return servicesPrice;
    },
    setType: function () {
        var option = this.config.type.find('option:checked');
        this.data.typePrice = option.data('price') || 0;
        $(".calc-type-info").html(option.data('details') || '');
    },
    setTotal: function () {
        var $total = this.config.total,
            $percentage = this.config.percentage,
            formattedPrice = accounting.formatNumber(this.data.price, 0, " ", ",");
        $total
            .attr('data-price', this.data.price)
            .text(formattedPrice);

        var coeff = this.getCoeff(parseFloat(this.config.square.val()) || 0);
        if(coeff == 1) {
            this.config.discount.parent().hide();
        } else if (coeff < 1) {
            this.config.discount.parent().show();
            this.config.discount.show();
            this.config.addprice.hide();
        } else {
            this.config.discount.parent().show();
            this.config.discount.hide();
            this.config.addprice.show();
        }
        $percentage.text(Math.round(Math.abs((1.00 - coeff)*100.00)) + "%");
    }
};
